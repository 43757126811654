import { isBrowser, loadScript } from '../utils';
import {
    GTMWindow,
    TrackableEvent,
    TrackingConfig,
    TrackingObject,
    LoadStatus,
    GTMProduct,
} from '../model';
import {
    trackingStarted,
    trackProductImpressionEvent,
    track404PageEvent,
    trackAddToBasketEvent,
    trackCheckoutEvent,
    trackContactCustomerServiceClickEvent,
    trackDownloadEvent,
    trackExternalLinkClickEvent,
    trackFormSubmissionEvent,
    trackPageViewEvent,
    trackProductDetailImpressionEvent,
    trackProductClickEvent,
    trackProductFilterResetEvent,
    trackProductSortingEvent,
    trackVideoEvent,
    trackMenuClickEvent,
    trackFilterSelectedEvent,
    trackPurchaseEvent,
    trackRemoveFromBasketEvent,
    trackSearchQueryEvent,
    trackSocialMediaEvent,
} from '../events';
import { pushEventFactory } from './pushEventFactory';

const getRejectionReason = (
    windowReference: Window | undefined,
    trackingEnabled: boolean,
    gtmId: string
) => {
    if (!isBrowser(windowReference) || !windowReference) {
        return 'Window document not available';
    }
    if (!trackingEnabled) {
        return 'Tracking is not enabled';
    }
    if (!gtmId) {
        return 'No container id was specified for google tag manager. Script load aborted.';
    }
    if (!((windowReference as unknown) as GTMWindow).dataLayer) {
        return 'GTM dataLayer is not defined. Please ensure that it has been defined in the <head> section.';
    }
    return undefined;
};

const noop = () => undefined as never;

const configurationDefaults: Partial<TrackingConfig> = {
    trackingTimeoutMs: 2000,
    overrideEvent: {
        trackPage: noop,
        trackSocialMedia: noop,
        trackAddToBasket: noop,
        trackRemoveFromBasket: noop,
        trackCheckout: noop,
        trackPurchase: noop,
        trackProductImpression: noop,
        trackProductDetailImpression: noop,
        trackSearchQuery: noop,
        trackProductClick: noop,
        track404Page: noop,
        trackFormSubmission: noop,
        trackContactCustomerServiceClick: noop,
        trackDownload: noop,
        trackVideo: noop,
        trackMenuClick: noop,
        trackExternalLinkClick: noop,
        trackFilterSelected: noop,
        trackProductFilterReset: noop,
        trackProductSorting: noop,
    },
};

export const configureTracking = (trackingConfiguration: TrackingConfig): TrackingObject => {
    const config = { ...configurationDefaults, ...trackingConfiguration };
    const { gtmId, trackingEnabled, overrideEvent, customDomain } = config;
    const windowReference: GTMWindow =
        !isBrowser(config.window) || !config.window
            ? {
                  google_tag_manager: undefined,
                  dataLayer: [],
                  document: {
                      title: 'unknown',
                      location: {
                          href: 'unknown',
                          pathname: 'unknown',
                      },
                  },
              }
            : ((config.window as unknown) as GTMWindow);

    let loadComplete: Promise<LoadStatus>;
    let trackStart = false;

    const rejectionReason = getRejectionReason(config.window, trackingEnabled, gtmId);

    if (rejectionReason) {
        loadComplete = new Promise((resolve) => resolve({ status: 'ERROR', rejectionReason }));
    } else {
        let loadResolve: (status: LoadStatus) => void;
        loadComplete = new Promise((resolve) => {
            loadResolve = resolve;
        });

        const { loadPromise, alreadyExists } = loadScript(
            config.window as Window,
            `${customDomain}/gtm.js?id=${gtmId}`
        );

        trackStart = !alreadyExists;

        loadPromise
            .then(() => {
                if (!windowReference.google_tag_manager) {
                    loadResolve({
                        status: 'ERROR',
                        rejectionReason: 'THEN: Script load was blocked',
                    });
                    return;
                }
                loadResolve({
                    status: 'READY',
                });
            })
            .catch(() => {
                loadResolve({
                    status: 'ERROR',
                    rejectionReason: 'CATCH: Script load was blocked',
                });
            });
    }

    const pushEvent = pushEventFactory(windowReference, config, loadComplete);

    if (trackStart) {
        loadComplete.then(() => pushEvent(trackingStarted()));
    }

    return {
        loadComplete,
        trackPage: (pageViewData) =>
            pushEvent({
                ...trackPageViewEvent(pageViewData, windowReference),
                ...overrideEvent?.trackPage?.(),
            }),
        trackSocialMedia: (mediaType) =>
            pushEvent({
                ...trackSocialMediaEvent(windowReference, mediaType),
                ...overrideEvent?.trackSocialMedia?.(),
            }),
        trackAddToBasket: (products, currencyCode) =>
            pushEvent({
                ...trackAddToBasketEvent(products, currencyCode),
                ...overrideEvent?.trackAddToBasket?.(),
            }),
        trackRemoveFromBasket: (products, currencyCode) =>
            pushEvent({
                ...trackRemoveFromBasketEvent(products, currencyCode),
                ...overrideEvent?.trackRemoveFromBasket?.(),
            }),
        trackCheckout: (products, step, currencyCode, option) =>
            pushEvent({
                ...trackCheckoutEvent(products, step, currencyCode, option),
                ...overrideEvent?.trackCheckout?.(),
            }),
        trackPurchase: (
            orderId,
            products,
            totalPrice,
            currencyCode,
            shippingPrice,
            vatPrice,
            coupon
        ) =>
            pushEvent({
                ...trackPurchaseEvent(
                    orderId,
                    products,
                    totalPrice,
                    currencyCode,
                    shippingPrice,
                    vatPrice,
                    coupon
                ),
                ...overrideEvent?.trackPurchase?.(),
            }),
        trackProductImpression: (products, currencyCode, impressionSource) =>
            pushEvent({
                ...trackProductImpressionEvent(products, currencyCode, impressionSource),
                ...overrideEvent?.trackProductImpression?.(),
            }),
        trackProductDetailImpression: (...args) =>
            pushEvent({
                ...trackProductDetailImpressionEvent(...args),
                ...overrideEvent?.trackProductDetailImpression?.(),
            }),
        trackProductClick: (product: GTMProduct[], listSource: string, currencyCode?: string) => {
            return pushEvent({
                ...trackProductClickEvent(product, listSource, currencyCode),
                ...overrideEvent?.trackProductClick?.(),
            });
        },
        trackSearchQuery: (...args) =>
            pushEvent({
                ...trackSearchQueryEvent(windowReference, ...args),
                ...overrideEvent?.trackSearchQuery?.(),
            }),
        track404Page: (referer) =>
            pushEvent({
                ...track404PageEvent(windowReference, referer),
                ...overrideEvent?.track404Page?.(),
            }),
        trackVideo: (...args) =>
            pushEvent({
                ...trackVideoEvent(...args),
                ...overrideEvent?.trackVideo?.(),
            }),
        trackFormSubmission: (formName, formType) =>
            pushEvent({
                ...trackFormSubmissionEvent(formName, formType),
                ...overrideEvent?.trackFormSubmission?.(),
            }),
        trackContactCustomerServiceClick: (contactType) =>
            pushEvent({
                ...trackContactCustomerServiceClickEvent(windowReference, contactType),
                ...overrideEvent?.trackContactCustomerServiceClick?.(),
            }),
        trackDownload: (fileName) =>
            pushEvent({
                ...trackDownloadEvent(windowReference, fileName),
                ...overrideEvent?.trackDownload?.(),
            }),
        trackExternalLinkClick: (externalLink) =>
            pushEvent({
                ...trackExternalLinkClickEvent(externalLink),
                ...overrideEvent?.trackExternalLinkClick?.(),
            }),
        trackMenuClick: (...args) =>
            pushEvent({
                ...trackMenuClickEvent(...args),
                ...overrideEvent?.trackMenuClick?.(),
            }),
        trackFilterSelected: (filterSelected, currency) =>
            pushEvent({
                ...trackFilterSelectedEvent(filterSelected, currency),
                ...overrideEvent?.trackFilterSelected?.(),
            }),
        trackProductFilterReset: () =>
            pushEvent({
                ...trackProductFilterResetEvent(),
                ...overrideEvent?.trackProductFilterReset?.(),
            }),
        trackProductSorting: (sortingSelected) =>
            pushEvent({
                ...trackProductSortingEvent(sortingSelected),
                ...overrideEvent?.trackProductSorting?.(),
            }),
        trackCustomEvent: (event: unknown) => pushEvent(event as TrackableEvent),
    };
};
